@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.App {
    min-width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
}

.content {
    flex: 1 1;
    display: grid;
    grid-template-rows: auto 1fr;
}

.main {
    display: grid;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.main__center {
    position: relative;
    transition: all .3s;
    padding-bottom: 50px;
}

/*# sourceMappingURL=app.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.sidebar {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: inherit;
    flex-direction: column;
    height: 100vh;
    width: 56px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 1px 0px 1px rgba(25, 31, 40, 0.2);
    transition: all .3s;
    overflow: hidden;
    z-index: 100;
}

.sidebar.open {
    width: 225px;
    transition: all .3s;
}

.sidebar__top {
    background-color: #393F49;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.sidebar__top__center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar__middle {
    flex: 1 1;
    margin-bottom: 2rem;
}

.sidebar__middle__link {
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: .5rem 0;
}

.sidebar__middle__link:hover {
    background-color: #EDF4FF;
}

.sidebar__middle__link.active_link {
    background-color: #D6DDEA;
    border-right: 3px solid #191F28;
}

.sidebar__middle__link.active_inner_link {
    box-shadow: 0px 3px 1px rgba(25, 31, 40, 0.2);
    position: relative;
    z-index: 10;
    background-color: #E5ECF9;
}

.sidebar__middle__innerLinks {
    height: 0;
    overflow: hidden;
    transition: all .5s;
    background-color: #F3FAFF;
}

.sidebar__middle__innerLink {
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: .5rem 0 .5rem 0px;
    transition: background-color .5s;
    font-size: 14px;
}

.sidebar__middle__innerLink:hover {
    background-color: #E5ECF9;
}

.sidebar__middle__innerLink.active {
    background-color: #D6DDEA;
    border-right: 3px solid #191F28;
}

.sidebar__middle__innerLink.abbr {
    padding: .5rem 0;
}

.sidebar__middle__link-text {
    flex-grow: 1;
    white-space: nowrap;
}

.sidebar__bottom {
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar__bottom__username {
    flex: 1 1;
    text-transform: capitalize;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
}

.sidebar__bottom__logout-btn {
    padding: .5rem !important;
    margin: 0 .5rem;
    border-radius: 10px;
}

.sidebar__bottom__logout-btn:hover {
    background-color: salmon;
}

.sidebar .svg-container {
    padding: 0 1rem;
}

/*# sourceMappingURL=sidebar.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.topbarCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: 0 auto;
}

.topbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: inset 0 -5px 0 0 #ffbe49;
}

.topbar__top {
    background-color: #191F28;
    display: flex;
    justify-content: inherit;
    align-items: center;
    position: relative;
}

.topbar__top__links {
    display: flex;
    flex-wrap: wrap;
    min-height: 60px;
    -webkit-column-gap: 40px;
    grid-column-gap: 40px;
    column-gap: 40px;
}

.topbar__top__links:not(:last-of-type) {
    border-bottom: 3px solid #484848;
}

.topbar__top__link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #D6DDEA;
    margin: 10px 0;
    cursor: pointer;
    font-size: 1rem;
    background: #484848;
    border-radius: 5px;
    padding: 0.5rem;
    position: relative;
}

.topbar__top__link:hover {
    color: #fff;
}

.topbar__top__link.active {
    border-bottom: 3px solid #FFBF49;
    font-weight: 500;
    color: #fff;
}

.topbar__top__link__badge {
    color: #fff;
    width: 1.2rem;
    height: 1.2rem;
    font-size: .8rem;
    border-radius: 50%;
    background-color: #ef6b6b;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    right: -8px;
}

.topbar__top__client {
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr auto;
    -webkit-column-gap: .3rem;
    grid-column-gap: .3rem;
    column-gap: .3rem;
    font-size: 20px;
    font-weight: 500;
    color: #FFBF49;
    cursor: pointer;
}

.topbar__top__logo {
    color: #fff;
    font-size: 24px;
}

.topbar__top__logo__yellow {
    color: #FFC45B;
}

.topbar__top__logo__white {
    color: #fff;
}

.topbar__bottom {
    min-height: 36px;
    background-color: #fff;
    display: flex;
    justify-content: inherit;
    align-items: center;
}

.topbar__bottom__browser-history {
    display: flex;
    flex-wrap: wrap;
}

.topbar__bottom__history {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr auto;
    cursor: default;
    font-size: 12px;
    color: #585E69;
}

.topbar__bottom__history__text {
    cursor: pointer;
}

.topbar__bottom__history__text:hover {
    color: #000;
}

.topbar__bottom__history__arrow {
    margin: 0 .5rem;
}

.topbar__bottom__history__arrow > svg {
    width: 14px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.topbar__popup-background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.4);
}

.topbar__popup-center {
    background: #191F28;
    color: #fff;
    padding: 1.5rem;
    border-radius: 10px;
    margin-top: 4.5rem;
}

.topbar__popup-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 3rem;
    grid-column-gap: 3rem;
    column-gap: 3rem;
    margin-bottom: 1rem;
}

.topbar__popup-row:last-of-type {
    margin-bottom: 1.5rem;
}

.topbar__popup-btn {
    width: 100%;
}

/*# sourceMappingURL=topbar.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.text__label {
    position: absolute;
    background-color: #fff;
    padding: 3px 5px;
    cursor: text;
    color: rgba(0, 0, 0, 0.54);
    left: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    white-space: nowrap;
    transition: all 250ms;
    pointer-events: none;
    border-radius: 5px;
}

.text__label.focused {
    z-index: 10;
    cursor: default;
    color: #015ecc;
    top: 1px;
    font-size: .8rem;
}

.text__label.filled {
    color: rgba(0, 0, 0, 0.54);
}

.text__input {
    display: block;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #B4BBC7;
    padding: 14px;
    font-size: 1rem;
    height: inherit;
}

.text__input:focus {
    outline-color: #015ecc;
}

.text__input:disabled {
    background-color: inherit;
    pointer-events: none;
}

input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px WHITE inset;
}

.password__eye {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.password__eye svg {
    width: 16px;
}

/*# sourceMappingURL=text.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.login {
    margin-bottom: 2rem;
}

.login__logo {
    color: #000;
    font-size: 5.5rem;
    font-weight: 500;
    text-align: center;
    width: 95%;
    margin: 4rem auto;
}

.login__logo__yellow {
    color: #FFC45B;
}

.login__center {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: auto;
    margin: 0 auto;
    padding: 2rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 15px 2px rgba(180, 187, 199, 0.8);
    border-radius: 20px;
}

.login__center__title {
    font-size: 1.3rem;
    font-weight: 500;
    text-align: center;
    padding-bottom: 30px;
}

.login__center__close-btn-container {
    position: absolute;
    top: 15px;
    right: 15px;
}

.login__center__form {
    display: grid;
    grid-row-gap: 20px;
    row-gap: 20px;
}

.login__center__login-btn {
    width: 360px;
    height: 50px;
    margin: 10px auto 0;
}

/*# sourceMappingURL=login.css.map */

/* No CSS *//*# sourceMappingURL=clients.css.map */
/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.loader-container {
    z-index: 10000000;
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 0;
}

.loader-container.fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    color: #fff;
    color: #00ff15;
}

.animated-background-loader {
    position: relative;
    min-height: 200px;
    margin-bottom: 40px;
    border-radius: 10px;
    background-color: #d4dce5;
    background: #d0d7dd;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animated-background-loader:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
    -webkit-animation: loading 1s infinite;
    animation: loading 1s infinite;
}

@-webkit-keyframes loading {
    100% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

@keyframes loading {
    100% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

/*# sourceMappingURL=loader.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.table-for-tasks-head-block {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 101;
    background: #EDF4FF;
}

.table-for-tasks-head-block .table__th {
    background-color: #393F49;
    color: #fff;
}

.table-for-tasks-head-block .table__th__center {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.table-for-tasks-head-block .table__th__value {
    text-align: center;
}

.table-for-tasks-head-block .legend {
    display: flex;
    align-items: center;
}

.table-for-tasks-head-block .legend__work-calendar {
    padding: .5rem;
    border-radius: 5px;
    background: #E5ECF9;
    border: 1px solid;
    transition: all .3s;
}

.table-for-tasks-head-block .legend__work-calendar:hover {
    background-color: #D6DDEA;
}

.table-for-tasks-head-block .legend__date {
    text-align: center;
    width: 350px;
    margin-left: auto;
    margin-right: 1px;
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.table-for-tasks-head-block .legend__date__title {
    cursor: pointer;
    padding: 3px;
    border-radius: 5px;
    transition: all .3s;
}

.table-for-tasks-head-block .legend__date__title:hover {
    background-color: lightblue;
}

.table-for-tasks-head-block .legend__date__week-calendar {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 2.5rem;
    z-index: 1000;
    box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.83);
}

.table-for-tasks-head-block .legend__date__arrow {
    background-color: lightblue;
    background-color: #393F49;
    border-radius: 5px;
}

.table-for-tasks-head-block .legend__date__arrow:hover {
    opacity: .8;
}

.table-for-tasks-block {
    margin-bottom: 20px;
    box-shadow: 0px 6px 4px -5px rgba(0, 0, 0, 0.66);
}

.table-for-tasks-block .table__td__button {
    background-color: #E5ECF9;
    cursor: pointer;
}

.table-for-tasks-block .table__td__button:hover {
    background-color: red !important;
}

.table-for-tasks-block .table__td__center {
    -webkit-column-gap: .4rem;
    grid-column-gap: .4rem;
    column-gap: .4rem;
    width: 100%;
}

.table-for-tasks-block .table__th__center {
    min-height: -webkit-min-content;
    min-height: min-content;
}

.table-caption-container {
    background-color: #393F49;
    min-height: 60px;
    display: flex;
    justify-content: inherit;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    padding: 11px;
    border-radius: 10px 10px 0px 0px;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    -webkit-column-gap: .8rem;
    grid-column-gap: .8rem;
    column-gap: .8rem;
}

.table-caption-container .table-caption {
    font-size: 1.8rem;
    font-weight: 500;
    color: #fff;
}

.table-caption-container .btns-container {
    flex: 1 1;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
}

.table-caption-container .btns-container button span {
    white-space: nowrap;
}

.table-caption-container .table-date-fields {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto auto;
    grid-column-gap: 20px;
    z-index: 110;
}

.table-caption-container .table-date-field {
    display: flex;
    justify-content: normal;
    align-items: center;
}

.table-caption-container .table-date-field__label {
    margin-right: 5px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
}

.table-caption-container .table-date-field .form-field {
    height: 36px;
    width: unset;
    margin: 0;
}

.table-caption-container .table-date-field .form-field .text__input {
    font-size: 14px;
}

.table-caption-container .table-date-field .form-field .text__input[name='year'], .table-caption-container .table-date-field .form-field .text__input[name='month'] {
    width: 90px;
}

.table-caption-container .table-date-field .form-field .text__input[name='date_to'], .table-caption-container .table-date-field .form-field .text__input[name='date_from'] {
    width: 130px;
}

.table-caption-container .table-date-field .form-field .svg-container svg {
    width: 20px;
}

.table-container {
    width: 100%;
    overflow: auto;
    background-color: #fff;
}

.table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    height: 1px;
}

.table__th, .table__td {
    border: 1px solid #D6DDEA;
    white-space: nowrap;
}

.table__th > div,
.table__th > a, .table__td > div,
.table__td > a {
    font-size: 13px;
    line-height: 1.5;
    transition: all .3s ease-in-out;
    white-space: pre-wrap;
    padding: 4px;
}

.table__th > div.unclickable,
.table__th > a.unclickable, .table__td > div.unclickable,
.table__td > a.unclickable {
    cursor: default;
}

.table__th {
    cursor: pointer;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #F3FAFF;
    z-index: 100;
}

.table__th__icon {
    width: 1%;
}

.table__th.ascending {
    box-shadow: inset 0 -5px 0 0 #FFBF49;
}

.table__th.descending {
    box-shadow: inset 0 5px 0 0 #FFBF49;
}

.table__th__center {
    display: flex;
    align-items: center;
    min-height: 60px;
    height: 100%;
    overflow: hidden;
}

.table__th__value {
    text-align: left;
    font-weight: 500;
    flex: 1 1;
}

.table__th__value__criterias {
    display: flex;
    margin-top: 2px;
}

.table__th__value__criterias p {
    font-weight: 400;
    font-size: 12px;
    background: #E5ECF9;
    border-radius: 5px;
    border: 1px solid #dadce0;
    padding: 0 3px;
}

.table__th__value__criterias p:not(:last-of-type) {
    margin-right: 10px;
}

.table__th__value__criterias p span {
    color: #F28238;
    color: #25bb32;
}

.table__th[is_today="true"] {
    background-color: #fff;
    color: #000;
}

.table__th__pin {
    position: absolute;
    right: 4px;
    top: 7px;
    border-radius: 3px;
    background: #E5ECF9;
    z-index: 10;
    padding: 2px;
}

.table__th__pin:hover {
    background-color: #B4BBC7;
}

.table__th__pin svg {
    width: 14px;
}

.table__th__hint {
    position: absolute;
    right: 4px;
    top: 7px;
    border-radius: 3px;
    background: #E5ECF9;
    z-index: 10;
    padding: 2px;
}

.table__th__hint__icon:hover {
    background: #B4BBC7;
}

.table__th__hint svg {
    width: 14px;
}

.table__th__unfold {
    position: absolute;
    right: 8px;
    bottom: 7px;
    z-index: 10;
}

.table__th__unfold svg {
    width: 16px;
}

.table__th__unfold svg path {
    fill: #B4BBC7;
}

.table__th:first-child {
    border-left-color: #ddd;
}

.table__td__center {
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.table__td__center .svg-container {
    width: 24px;
    height: 24px;
    border-radius: 5px;
}

.table__td__center.open .svg-container,
.table__td__center.fill .svg-container,
.table__td__center.task_comment .svg-container,
.table__td__center.tz_comment .svg-container {
    background-color: #FFD750;
}

.table__td__center.open .svg-container:hover,
.table__td__center.fill .svg-container:hover,
.table__td__center.task_comment .svg-container:hover,
.table__td__center.tz_comment .svg-container:hover {
    background-color: #FFBF49;
}

.table__td__center.open .svg-container[data-editable=false],
.table__td__center.fill .svg-container[data-editable=false],
.table__td__center.task_comment .svg-container[data-editable=false],
.table__td__center.tz_comment .svg-container[data-editable=false] {
    background-color: #d7d7d7;
    cursor: default;
}

.table__td__center.remove .svg-container {
    background-color: #ffc5c5;
}

.table__td__center.remove .svg-container svg {
    width: 17px;
}

.table__td__center.remove .svg-container:hover {
    background-color: #ff8787;
}

.table__td__center.edit .svg-container {
    background-color: #FFD750;
}

.table__td__center.edit .svg-container svg {
    width: 17px;
}

.table__td__center.edit .svg-container:hover {
    background-color: #FFBF49;
}

.table__td__center.tz_status textarea {
    text-align: center;
}

.table__td__center > .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 4px;
    background-color: #000;
}

.table__td__center > .circle.red {
    background-color: #e66b6b;
}

.table__td__center > .circle.green {
    background-color: #25bb32;
}

.table__td__center > .buttons {
    display: flex;
    -webkit-column-gap: 5px;
    grid-column-gap: 5px;
    column-gap: 5px;
}

.table__td__center > .buttons.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 5px;
    row-gap: 5px;
}

.table__td__center > .buttons button.blue-button {
    background-color: #5bc0de;
}

.table__td__center > .buttons button.blue-button:hover {
    background-color: #31b0d5;
}

.table__td__center > .buttons button.yellow-button {
    background-color: #FFD750;
}

.table__td__center > .buttons button.yellow-button:hover {
    background-color: #FFBF49;
}

.table__td__center > .buttons button.green-button {
    background-color: #6be675;
}

.table__td__center > .buttons button.green-button:hover {
    background-color: #25bb32;
}

.table__td__center > .buttons button.red-button {
    background-color: #ffc5c5;
}

.table__td__center > .buttons button.red-button:hover {
    background-color: #ffa8a8;
}

.table__td__center > .buttons button svg {
    width: 16px;
}

.table__td__center.number_field {
    white-space: nowrap;
}

.table__td__add-button {
    background-color: #FFBF49;
    cursor: pointer;
}

.table__row {
    color: #393F49;
}

.table__row.clickable {
    cursor: pointer;
}

.table__row.unclickable {
    cursor: default;
}

.table__row:hover .table__td {
    background-color: #E5ECF9 !important;
}

.table__row:hover .table__td__add-button {
    background-color: #FAA843 !important;
}

.table__row__add-new-row {
    background-color: #E5ECF9;
}

.table .form-field {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.table .form-field.select input,
.table .form-field.select textarea, .table .form-field.date input,
.table .form-field.date textarea {
    padding-right: 23px !important;
}

.table .form-field.select input:disabled,
.table .form-field.select textarea:disabled, .table .form-field.date input:disabled,
.table .form-field.date textarea:disabled {
    padding-right: 0 !important;
}

.table .form-field.select input::-webkit-input-placeholder,
.table .form-field.select textarea::-webkit-input-placeholder, .table .form-field.date input::-webkit-input-placeholder,
.table .form-field.date textarea::-webkit-input-placeholder {
    font-size: .7rem;
}

.table .form-field.select input::-webkit-input-placeholder, .table .form-field.select textarea::-webkit-input-placeholder, .table .form-field.date input::-webkit-input-placeholder, .table .form-field.date textarea::-webkit-input-placeholder {
    font-size: .7rem;
}

.table .form-field.select input::placeholder,
.table .form-field.select textarea::placeholder, .table .form-field.date input::placeholder,
.table .form-field.date textarea::placeholder {
    font-size: .7rem;
}

.table .form-field input,
.table .form-field textarea {
    font-size: 13px;
    padding: 4px;
    border: none;
}

.table .form-field textarea {
    height: 22px;
}

.table .form-field__open-button {
    padding: 0 !important;
    right: 0px !important;
}

.table .form-field__open-button svg {
    width: 18px;
}

.table .form-field__bottom {
    top: 27px;
}

.table .select__option {
    font-size: 12px;
    padding: 4px 8px;
}

.table .col-handle {
    position: absolute;
    width: 10px;
    background: #ccbebe;
    height: 100%;
    right: -6px;
    border-radius: 5px;
}

.table .col-handle:hover {
    background: #8a8181;
}

.table__icon {
    width: 32px;
    justify-content: center;
}

.filter {
    margin-right: .3rem;
}

.filter__icon {
    border-radius: 3px;
    transition: all .3s;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E5ECF9;
}

.filter__icon:hover {
    background-color: #FFE34F;
}

.filter__icon.active {
    background-color: #FFBF49;
}

.filter__icon svg {
    width: 14px;
}

.filter__popup {
    position: absolute;
    top: 2.6rem;
    top: 38px;
    background-color: #f1f1f1;
    width: 80%;
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.2);
}

.filter__popup input {
    width: 100%;
    padding: .1rem;
}

.filter__popup input:focus {
    outline: none;
}

.div1 {
    background: red;
    width: 50px;
    height: 50px;
    position: relative;
}

.in {
    position: absolute;
    background: blue;
    width: 10px;
    height: 50px;
    right: 0;
}

/*# sourceMappingURL=table.css.map */

.radio {
    width: 360px;
    padding-left: 17px;
}

/*# sourceMappingURL=radio.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
/*# sourceMappingURL=number.css.map */
.checkbox {
    width: 360px;
    padding-left: 17px;
}

.checkbox2 .MuiFormControlLabel-label {
    font-size: 13px;
    margin-left: .3rem;
}

/*# sourceMappingURL=checkbox.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.select {
    position: relative;
    font-size: 1rem;
}

.select input {
    cursor: pointer;
}

.select .fixed {
    position: fixed;
}

.select__options {
    background-color: white;
    box-shadow: 0px 2px 10px 0px rgba(180, 187, 199, 0.8);
    padding: 3px 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 280px;
    overflow-y: auto;
}

.select__option {
    padding: 10px;
    cursor: pointer;
}

.select__option:hover {
    background-color: #E5ECF9;
}

.select__option.selected {
    background-color: #D6DDEA;
}

.select__option.delete-option {
    background-color: #f49696;
}

.select__value {
    position: absolute;
    border: none;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 14px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #B4BBC7;
    height: inherit;
}

/*# sourceMappingURL=select.css.map */

/* No CSS *//*# sourceMappingURL=textarea_mu.css.map */
.date {
    position: relative;
}

.date__react-calendar {
    width: 100%;
    min-width: 350px;
}

.date__react-calendar__number {
    max-width: 350px;
}

.date__react-calendar-for-popup {
    margin: 1rem auto;
    width: 430px;
    height: 302px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
}

/*# sourceMappingURL=date.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.send-button {
    height: 36px;
    background: #b8c9c6;
    background: #B4BBC7;
    width: 36px;
    border-radius: 5px;
}

.send-button:hover {
    background: #949BA7;
}

.send-button svg {
    width: 20px;
}

/*# sourceMappingURL=send-button.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.popup-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-container .popup2 {
    max-width: 90%;
    width: 1000px;
}

.popup {
    cursor: auto;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    overflow: hidden;
    margin: 0 auto;
    padding: 2rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 15px 2px rgba(180, 187, 199, 0.8);
    border-radius: 20px;
    max-width: 90%;
    max-height: 90%;
    min-width: 440px;
}

.popup .loader-container {
    position: unset;
}

.popup.w500 {
    min-width: 500px;
    max-height: 500px;
}

.popup__content {
    max-height: 70vh;
    overflow: auto;
}

.popup__title {
    font-size: 1.3rem;
    font-weight: 500;
    text-align: center;
    padding-bottom: 30px;
    width: 84%;
    margin: 0 auto;
}

.popup__close-btn-container {
    position: absolute;
    top: 15px;
    right: 15px;
}

.popup__submit-btn {
    width: 360px;
    height: 50px;
    margin: 10px auto 0;
}

.popup__form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-row-gap: 20px;
    grid-column-gap: 2rem;
    padding: .5rem;
}

.popup__form div {
    align-self: flex-start;
    justify-items: center;
}

.popup__form__subtitle {
    font-size: 1rem;
    font-weight: 500;
    color: #393F49;
    text-align: center;
    min-height: 40px;
}

.popup__form__children {
    display: grid;
    grid-row-gap: 15px;
    row-gap: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.popup__form_field_button {
    color: #a2a2b9;
    margin-left: auto;
    margin-top: 1rem;
}

.popup__form_field_button:last-of-type {
    margin-top: .5rem;
}

.popup__form_field_button:hover {
    color: #1616d2;
}

.popup__log-container {
    display: grid;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    max-height: 70vh;
    overflow: auto;
}

.popup__log {
    background-color: #393F49;
    color: #fff;
    border-radius: 10px;
    padding: 20px 30px;
    display: grid;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.popup__log__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
}

.popup__log__author {
    font-size: 18px;
    font-weight: 500;
}

.popup__log__timestamp {
    color: #B4BBC7;
    font-size: 12px;
}

.popup__log__text {
    color: #E5ECF9;
    font-size: 16px;
    font-weight: 400;
    white-space: pre-wrap;
}

.popup__fill-total {
    margin-top: 1rem;
    text-align: center;
    font-weight: 500;
}

.popup__fill-total__label {
    margin-right: 1rem;
    color: #6C727E;
}

.popup__fill-total__value {
    color: #393F49;
}

.popup__hr {
    border-radius: 5px;
    height: 3px;
    background-color: #949BA7;
    width: 99%;
    margin: 0 auto;
}

.popup__top {
    display: flex;
    align-items: center;
    -webkit-column-gap: .5rem;
    grid-column-gap: .5rem;
    column-gap: .5rem;
    padding-bottom: 30px;
}

.popup__top__title {
    font-size: 1.3rem;
    font-weight: 500;
}

.popup__date-fields {
    display: flex;
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    align-items: center;
}

.popup__date-field {
    width: 190px;
    display: flex;
    -webkit-column-gap: .5rem;
    grid-column-gap: .5rem;
    column-gap: .5rem;
    align-items: center;
}

.popup__date-field .form-field {
    width: unset;
}

.popup__comment-form {
    margin-bottom: 1rem;
}

.popup__comment-form .form-field {
    width: 100%;
}

.popup__comment-form button {
    margin-top: .5rem;
    margin-left: auto;
}

.popup__comment-form + .popup__logs > .popup__log-container {
    max-height: 55vh;
}

.popup2 {
    cursor: auto;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 90%;
    overflow: auto;
    margin: 0 auto;
    padding: 2rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 15px 2px rgba(180, 187, 199, 0.8);
    border-radius: 20px;
}

.popup2__title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 30px;
    width: 84%;
    margin: 0 auto;
}

.popup2__close-btn-container {
    position: absolute;
    top: 15px;
    right: 15px;
}

.popup2__form {
    display: grid;
    justify-items: center;
    grid-row-gap: 20px;
    grid-column-gap: 2rem;
}

.popup2__form div {
    align-self: flex-start;
}

.popup2__form__field {
    display: grid;
    grid-template-columns: 370px 500px;
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    align-items: center;
    border-radius: 5px;
}

.popup2__form__field .form-field {
    width: inherit;
}

.popup2__form__label {
    font-size: 13px;
    text-align: right;
}

.popup2__form__targetings {
    background: #ffdddd;
    border-radius: 10px;
    width: 100%;
    max-width: 886px;
    padding: 1rem;
}

.popup2__form__targetings .warning-word {
    font-size: 24px;
    font-weight: 500;
    color: #bb2525;
}

.popup2__form__targetings .warning-text {
    margin: 1rem 0;
}

.popup2__form__targetings .form-field {
    width: inherit;
}

.popup2__btns-container {
    display: flex;
    justify-content: center;
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    margin-top: 2rem;
}

.popup2__submit-btn {
    height: 32px;
    font-size: 14px !important;
    width: 167px;
}

.popup2__close-btn {
    background-color: #E5ECF9 !important;
    height: 32px;
    font-size: 14px !important;
    width: 167px;
}

.popup2__close-btn:hover {
    background-color: #D6DDEA !important;
}

@media (max-width: 500px) {
    .popup {
        min-width: 95%;
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content;
        padding: 1rem;
    }

    .popup__form {
        grid-template-columns: 1fr;
    }

    .popup__form > .form-field {
        width: 100%;
    }

    .popup__form > .form-field .form-field {
        width: inherit;
    }

    .popup__submit-btn {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}

/*# sourceMappingURL=popup.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.close-btn {
    background: #E5ECF9;
    border-radius: 50%;
    padding: 0.5rem;
}

.close-btn:hover {
    background-color: #D6DDEA;
}

.close-btn:active {
    background-color: #B4BBC7;
}

.close-btn svg {
    width: 16px;
}

/*# sourceMappingURL=close-button.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.multi-select .form-field__top {
    border-radius: 10px;
    border: 1px solid;
    cursor: pointer;
}

.multi-select .form-field__top.focused {
    border-color: #015ecc;
}

.multi-select .form-field__top .text {
    min-height: 56px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.multi-select .form-field__bottom .text {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.multi-select .text__label.focused {
    top: 0px;
}

.multi-select__values {
    padding: 14px 40px 14px 14px;
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: .4rem;
    row-gap: .4rem;
}

.multi-select__value-container {
    position: relative;
    margin-right: 2rem;
}

.multi-select__value-text {
    padding: 0.2rem 0.4rem;
    background-color: #E5ECF9;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: text;
}

.multi-select__value-remove-button {
    background-color: #E5ECF9;
    transition: all .3s;
    padding: 0.2rem 0.4rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -17px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
}

.multi-select__value-remove-button:hover {
    background-color: #dc3545;
    background-color: #ec1616;
    background-color: #393F49;
    color: #fff;
}

.multi-select .select__options .text__input {
    width: 96%;
    background-color: gainsboro;
    padding: 0.5rem;
    margin: 3px auto;
}

/*# sourceMappingURL=multi_select.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.checkbox_textarea {
    width: inherit !important;
    width: 500px !important;
}

.checkbox_textarea .checkbox {
    width: inherit;
}

.checkbox_textarea .textarea {
    width: inherit;
}

/*# sourceMappingURL=checkbox_textarea.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.select_text {
    width: inherit !important;
}

.select_text .select {
    flex: 30% 1;
    width: unset;
}

.select_text .select input {
    border-right: 1px solid #B4BBC7 !important;
}

.select_text .text {
    flex: 70% 1;
    width: unset;
}

/*# sourceMappingURL=select_text.css.map */

/* No CSS *//*# sourceMappingURL=fills.css.map */
/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.footer {
    margin-top: 1rem;
    background-color: #E5ECF9;
    padding: .5rem;
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.footer__text {
    font-size: 12px;
    text-align: center;
}

/*# sourceMappingURL=footer.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.client__info {
    margin-bottom: 1rem;
    background-color: #E5ECF9;
    padding: 30px 41px 30px 30px;
    border-radius: 10px;
    display: grid;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.client__info .delete {
    background-color: #e66b6b;
    color: #fff;
}

.client__info .delete:hover {
    background-color: #bb2525;
}

.client__info__top {
    display: flex;
    align-items: center;
}

.client__info__top > .svg-container {
    padding: 0 1rem;
}

.client__info__top > .svg-container:hover svg path {
    fill: #000;
}

.client__info__inline {
    display: flex;
    justify-content: normal;
    align-items: center;
    flex-wrap: wrap;
}

.client__name {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #393F49;
}

.client__name__inline {
    font-size: 2.25rem;
    color: #191F28;
}

.client__data-container {
    display: flex;
    flex-wrap: wrap;
}

.client__data-container__inline {
    margin: 0 1rem;
}

.client__data {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr auto;
    cursor: default;
}

.client__data__right {
    margin: 0 1rem;
}

.client__data__right svg {
    fill: #949BA7;
}

.client__key {
    font-size: 12px;
    color: #6C727E;
    text-transform: capitalize;
}

.client__value {
    font-size: 16px;
    color: #393F49;
    text-transform: capitalize;
}

/*# sourceMappingURL=client-info.css.map */

/* No CSS *//*# sourceMappingURL=client.css.map */
/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.feedback {
    position: fixed;
    top: 4.1rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    background-color: rgba(144, 252, 198, 0.308);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
    padding: .5rem;
    display: none;
    border-radius: 10px;
    background-color: #8ee5ff;
    color: #1c4b72;
    white-space: pre-wrap;
}

.feedback.show {
    display: block;
    z-index: 10000;
}

.feedback.show.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

/*# sourceMappingURL=feedback.css.map */

/* No CSS *//*# sourceMappingURL=brand.css.map */
/* No CSS *//*# sourceMappingURL=tzs_kpi.css.map */
/* No CSS *//*# sourceMappingURL=tzs_billing.css.map */
/* No CSS *//*# sourceMappingURL=nomenclatures.css.map */
/* No CSS *//*# sourceMappingURL=providers.css.map */
/* No CSS *//*# sourceMappingURL=provider.css.map */
/* No CSS *//*# sourceMappingURL=fill.css.map */
/* No CSS *//*# sourceMappingURL=rates.css.map */
/* No CSS *//*# sourceMappingURL=departments.css.map */
/* No CSS *//*# sourceMappingURL=positions.css.map */
/* No CSS *//*# sourceMappingURL=permissions.css.map */
/* No CSS *//*# sourceMappingURL=team.css.map */
/* No CSS *//*# sourceMappingURL=staff.css.map */
/* No CSS *//*# sourceMappingURL=staff_permissions.css.map */
/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.table-tz-block {
    margin-bottom: 40px;
}

.table-tz-block .table__td__center {
    width: inherit;
    height: 100%;
}

.table-tz-block .table__th {
    cursor: unset;
}

.table-tz-block .table__th__value {
    display: flex;
    align-items: center;
    -webkit-column-gap: 5px;
    grid-column-gap: 5px;
    column-gap: 5px;
}

.table-tz-block .table__th__value a {
    display: flex;
}

.table-tz-block .table__th__value a svg {
    width: 16px;
}

/*# sourceMappingURL=table_tz.css.map */

.popup-form-field .text__input {
    background-color: #f2f2f2;
}

.popup-form-field .select__options {
    margin-top: 5px;
}

/*# sourceMappingURL=popup_field.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.swipe-up-container {
    transition: all .4s;
}

.swipe-up-container.hide {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}

.swipe-up {
    background: white !important;
    position: fixed;
    /* top: 0; */
    left: 0;
    width: 100%;
    height: 90%;
    bottom: 0;
    padding: 60px 30px 30px;
    cursor: auto;
}

.swipe-up__close-btn {
    position: absolute;
    top: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.swipe-up__close-btn svg {
    width: 35px;
}

.swipe-up__close-btn svg path {
    fill: #B4BBC7;
}

.swipe-up__close-btn:hover svg path {
    fill: #6C727E;
}

.swipe-up .table-block {
    margin-bottom: 0;
}

.swipe-up__confirm-form {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: end;
    -webkit-column-gap: .2rem;
    grid-column-gap: .2rem;
    column-gap: .2rem;
}

.swipe-up__confirm-form input,
.swipe-up__confirm-form label {
    cursor: pointer;
}

/*# sourceMappingURL=swipe-up.css.map */

/* No CSS *//*# sourceMappingURL=tz_kpi.css.map */
/* No CSS *//*# sourceMappingURL=account.css.map */
/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.feedback__btn {
    background: #f1d3d3;
    padding: .4rem;
    border-radius: 5px;
    margin: 10px auto 0;
}

.feedback__btn:hover {
    background-color: #c56464;
}

/*# sourceMappingURL=feedback_2.css.map */

/* No CSS *//*# sourceMappingURL=kpi_table.css.map */
/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.server-error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ebf7ff;
    z-index: 10000;
}

.server-error {
    width: 90%;
}

.server-error .status-code {
    font-size: 9rem;
    font-weight: 500;
    border-radius: 10px;
    text-align: center;
    padding: 1rem;
}

.server-error .error-word {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: 500;
}

.server-error .feedback-message {
    text-align: center;
    font-size: 1.1rem;
    margin-bottom: 2rem;
}

.server-error .btns-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 50%;
    width: 400px;
    margin: 0 auto;
}

.server-error .btns-container .btn {
    font-size: .9rem;
    padding: 1rem;
    background: #dddddd;
    background: #D6DDEA;
    border-radius: 10px;
    display: flex;
    -webkit-column-gap: .4rem;
    grid-column-gap: .4rem;
    column-gap: .4rem;
}

.server-error .btns-container .btn:hover {
    background: #bcc2cc;
}

/*# sourceMappingURL=server_error.css.map */

/* No CSS *//*# sourceMappingURL=kpi_archive.css.map */
/* No CSS *//*# sourceMappingURL=kpi_analysis.css.map */
/* No CSS *//*# sourceMappingURL=avrs.css.map */
/* No CSS *//*# sourceMappingURL=avr_new.css.map */
/* No CSS *//*# sourceMappingURL=tz_kpi.css.map */
/* No CSS *//*# sourceMappingURL=avr.css.map */
/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.helper__heading {
    font-weight: 500;
    text-align: center;
}

.helper__container {
    margin-bottom: 2rem;
    padding: 1rem 0;
}

.helper__item {
    background-color: #E5ECF9;
    margin: 0 3px 10px;
    border-radius: 3px;
    box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.83);
}

.helper__item:hover {
    box-shadow: 0px 1px 6px -2px rgba(0, 0, 0, 0.83);
}

.helper__title {
    cursor: pointer;
    padding: 1rem;
    font-weight: 500;
    font-size: 1.3rem;
    position: relative;
}

.helper__title:hover {
    background-color: #d1dff5;
}

.helper__title::after {
    content: '\02795';
    position: absolute;
    right: 1rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 13px;
    color: #777;
    margin-left: 5px;
}

.helper__title.active::after {
    content: "\2796";
    /* Unicode character for "minus" sign (-) */
}

.helper__body__container {
    height: 0;
    transition: height .3s linear;
    overflow: hidden;
}

.helper__body {
    padding: 1rem;
}

.helper__body .helper__section:not(:last-child) {
    padding-bottom: 1rem;
}

.helper__body .helper__section h3 {
    padding-bottom: .3rem;
}

.helper__video {
    float: right;
    max-width: 100%;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.helper__list {
    margin-left: 1rem;
}

.helper__list-item {
    padding-top: 1rem;
}

.helper__list-item p {
    padding-top: .3rem;
    text-align: justify;
}

.helper__inner-list {
    margin-left: 2rem;
    list-style: lower-alpha outside;
}

.helper__inner-list-item {
    font-size: .9rem;
    padding-top: .5rem;
}

.helper__link {
    text-decoration: underline !important;
    color: blue !important;
}

.helper__blockquote {
    display: block;
    padding: 0.3rem;
    border: 1px dotted;
    margin: 0.3rem 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #f5f5f5;
}

.helper__note {
    font-size: .8rem;
}

.helper .red {
    background-color: #f49696;
}

.helper .orange {
    background-color: #ffcb85;
}

.helper .green {
    background-color: #a3f79a;
}

/*# sourceMappingURL=helper.css.map */

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.alert__background {
    z-index: 10000 !important;
}

.alert {
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    max-width: 90%;
    width: 500px;
    position: fixed;
    top: 1%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 100000;
    box-shadow: 0px 1px 15px -2px rgba(214, 214, 214, 0.83);
}

.alert__title {
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
}

.alert__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.alert__button {
    background-color: #2d87c8;
    color: #E5ECF9;
    padding: .5rem 1rem;
    border-radius: 5px;
}

.alert__button__cancel {
    color: #2d87c8;
    background-color: #E5ECF9;
}

.alert__button:hover {
    opacity: .8;
    box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.83);
}

/*# sourceMappingURL=alert.css.map */

/* No CSS *//*# sourceMappingURL=tasks.css.map */
/* No CSS *//*# sourceMappingURL=user_tasks.css.map */
.switch-field {
    margin: 0 10px;
}

/*# sourceMappingURL=switch.css.map */

/* No CSS *//*# sourceMappingURL=holidays.css.map */
/* No CSS *//*# sourceMappingURL=weekends.css.map */
/* No CSS *//*# sourceMappingURL=staff_projects.css.map */
/* No CSS *//*# sourceMappingURL=properties_bonus.css.map */
/* No CSS *//*# sourceMappingURL=workload.css.map */
.bar-chart {
    position: relative;
}

.bar-chart__settings {
    position: absolute;
    right: 0;
    top: 10px;
}

.bar-chart__settings .dnd-group {
    right: 0;
    top: 40px;
    left: unset;
}

/*# sourceMappingURL=bar-chart.css.map */

/* No CSS *//*# sourceMappingURL=additional.css.map */
/* No CSS *//*# sourceMappingURL=kpi_analysis_archive.css.map */
/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.reports__download-income {
    display: flex;
    align-items: center;
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    background: #E5ECF9;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.reports__download-income__form {
    display: flex;
    -webkit-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
}

.reports__download-income__form .form-field.select {
    width: 100px;
    height: 40px;
}

.reports__download-income__form .form-field.select:first-of-type {
    width: 75px;
}

.reports__download-income__form .form-field.select:last-of-type {
    width: 230px;
}

/*# sourceMappingURL=reports.css.map */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #EDF4FF;
}

button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

button.button {
    background-color: #FFBF49;
    border-radius: 10px;
    font-size: 1rem;
}

button.button:hover {
    background-color: #FAA843;
}

button.button-2 {
    padding: 9px 24px;
    max-height: 36px;
}

button.button-2 svg {
    width: 14px;
    margin-right: 10px;
}

button.button-ml {
    margin-left: 1rem;
}

button.confirm {
    background-color: #98CF97;
    color: #fff;
}

button.confirm:hover {
    background-color: #8cc98b;
    background-color: #25bb32;
}

button.confirm .svg-container svg path {
    fill: #fff;
}

ul {
    list-style: none;
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover, a:active, a:focus {
    text-decoration: none;
    color: inherit;
}

abbr {
    text-decoration: none;
}

.svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

svg {
    cursor: pointer;
    height: auto;
    pointer-events: none;
}

svg.white {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

svg.white path {
    fill: white;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

svg.yellow path {
    fill: #FFBF49;
}

svg.yellow:hover path {
    fill: #FFE34F;
}

svg.grey path {
    fill: #585E69;
}

.form-field {
    width: 360px;
    position: relative;
}

.form-field .form-field {
    width: inherit;
    height: inherit;
}

.form-field.flex {
    display: flex;
}

.form-field__top {
    position: relative;
    height: inherit;
}

.form-field__open-button {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
    background-color: inherit;
    padding: 5px;
}

.form-field__open-button.disabled {
    background-color: transparent;
}

.form-field__bottom {
    position: absolute;
    margin-top: .3rem;
    top: 55px;
    left: 0;
    right: 0;
    width: inherit;
    border-radius: 5px;
    z-index: 10000001;
}

.form-field__bottom__fixed {
    max-width: 350px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.form-field__popup {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

form .uneditable label,
form .uneditable input,
form .uneditable textarea {
    background: #B4BBC7;
    background: #ddd !important;
}

form .uneditable .checkbox label {
    background: unset !important;
}

form .radio.uneditable label {
    background: unset !important;
}

.background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background: rgba(25, 31, 40, 0.5);
    z-index: 1000;
    cursor: pointer;
}

.tables {
    display: grid;
    grid-row-gap: 2rem;
    row-gap: 2rem;
}

.tables .table-block {
    overflow: unset;
}

.tables .table-container {
    overflow: unset;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.tables .table__th, .tables .table__td {
    white-space: nowrap;
}

.tables .table__th > div, .tables .table__td > div {
    width: inherit;
}

.tables textarea {
    font-size: 12px;
}

.dnd-group {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 10000;
    min-width: 232px;
    max-height: 309px;
    overflow: auto;
    background: #F7F9FE;
    box-shadow: 0px 2px 15px 2px rgba(180, 187, 199, 0.8);
    border-radius: 5px;
    color: #393F49;
}

.dnd-group .dnd-item {
    padding: 0 1rem;
    border-bottom: 1px solid #B4BBC7;
    min-height: 38px;
    display: flex;
    justify-content: normal;
    align-items: center;
    cursor: move;
}

.dnd-group .dnd-item.start {
    opacity: .5;
}

.dnd-group .dnd-item.over {
    background-color: #393F49;
    color: #fff;
}

.dnd-group .dnd-item:hover {
    background-color: #E5ECF9;
}

.dnd-group .dnd-item.reset-settings-btn {
    background: #D6DDEA;
    cursor: pointer;
    justify-content: flex-end;
    font-size: .8rem;
    /* background: red; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    border-radius: 5px;
    margin: 5px auto;
    height: 25px;
    min-height: unset;
}

.dnd-group .dnd-item.reset-settings-btn:hover {
    background: #B4BBC7;
}

.dnd-group .dnd-item.reset-settings-btn.settings-disabled {
    background-color: #ffcf4a;
}

.dnd-group .dnd-item.reset-settings-btn.settings-disabled:hover {
    background-color: #ffcf4a;
}

.bold-comma {
    font-weight: 700;
    font-size: 18px;
}

.swipe-up .pointer-events-none {
    pointer-events: none;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 16px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Directional buttons at the top and bottom of the scrollbar */
::-webkit-scrollbar-button {
    display: none;
}

/*# sourceMappingURL=global-styles.css.map */

